import React, { useState, useEffect } from 'react';
import DateSelector from './components/dateslot';
import TimeSlotList from '../src/components/timeslot';
import './App.css'
import logo from './logo.jpg'
import db from './components/firebase'
import payment_qr from './payment_qr.jpeg'
import { useSpring, animated } from '@react-spring/web';
import { addDoc, collection } from 'firebase/firestore';
import ThankYouScreen from './components/ThankYouScreen';

const App = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [name, setName] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [age, setAge] = useState('');
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState('');
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);

  const fadeIn = useSpring({ opacity: 1, from: { opacity: 0 } });
  const slideUp = useSpring({ transform: 'translateY(0)', from: { transform: 'translateY(50px)' } });

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedSlots([]);
  };

  const handleSlotSelection = (slotTime) => {
    setSelectedSlots([slotTime]);
    setShowContactDetails(true);
  };

  const validate = () => {
    let errors = [];

    if (!name.trim()) {
      errors.push('Patient Name is required');
    }

    if (!phoneNo.trim()) {
      errors.push('Phone No is required');
    } else if (!/^\d{10}$/.test(phoneNo.trim())) {
      errors.push('Phone No must be a valid 10-digit number');
    }

    if (!age.trim()) {
      errors.push('Age is required');
    } else if (isNaN(age) || age <= 0 || age > 120) {
      errors.push('Please enter a valid age between 1 and 120');
    }

    setErrors(errors.join(', '));
    return errors.length === 0;
  };

  const handleSaveDetails = async () => {
    if (validate()) {
      try {
        let [timeString, period] = selectedSlots[0].split(' ');
        let [hour, minute] = timeString.split(':').map(Number);

        if (period.toLowerCase() === 'pm' && hour !== 12) {
          hour += 12;
        } else if (period.toLowerCase() === 'am' && hour === 12) {
          hour = 0;
        }

        const appTime = new Date(selectedDate);
        appTime.setHours(hour, minute, 0, 0);

        await addDoc(collection(db, 'Appointments'), {
          Name: name,
          Contract_No: phoneNo,
          Age: age,
          Comments: comment,
          App_time: new Date(appTime),
          Status: true,
        });

        setBookingSuccess(true);

        // Reset all values after saving details
        setName('');
        setPhoneNo('');
        setAge('');
        setComment('');
        setPaymentMethod('');
        setSelectedSlots([]);
        setSelectedDate(new Date());
        setShowQRCode(false);
        setShowContactDetails(false);
        setShowPaymentOptions(false);

        setTimeout(() => {
          setBookingSuccess(false);
        }, 20000);

      } catch (error) {
        console.error('Error saving details: ', error);
        alert('Failed to book appointment. Please try again.');
      }
    }
  };

  // const handlePaymentOptionSelect = (method) => {
  //   setPaymentMethod(method);
  // };

  const handleConfirmPayment = () => {
    if (paymentMethod === 'UPI') {
      setShowQRCode(true);
      // setShowPaymentOptions(false)/
    
    } else {
      setShowQRCode(false)
      handleSaveDetails();
    }
  };

  const handlePaymentOptionSelect = (method) => {
    setPaymentMethod(method);
    if (method === 'UPI') {
      setShowQRCode(true);
    }
  };

  return (
    <div className='App'>
      <animated.div className='header' style={fadeIn}>
        <img src={logo} height='45' width='45' className='logo' alt='Logo' />
        <h1 className='title'>Blossoms Hospital</h1>
      </animated.div>
      <h2>Book your appointment</h2>
      {bookingSuccess ? (
        <ThankYouScreen onClose={() => setBookingSuccess(false)}  />
      ) : (
        <>
          {!showContactDetails && !showPaymentOptions && (
            <>
              <DateSelector selectedDate={selectedDate} handleDateChange={handleDateChange} />
              <TimeSlotList selectedDate={selectedDate} selectedSlots={selectedSlots} handleSlotSelection={handleSlotSelection} />
            </>
          )}

          {showContactDetails && !showPaymentOptions && (
            <div className='container'>
              <animated.div className='container' style={slideUp}>
                <div className='card'>
                  <h1 className='form-title'>Contact Details</h1>
                  <p className='info-text'>You have selected an appointment with the doctor at {selectedSlots[0]} on {selectedDate.toLocaleDateString()}.</p>
                  <p className='info-text'>Please provide your details in the form below to proceed further.</p>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (validate()) {
                        setShowPaymentOptions(true); 
                      }
                    }}
                  >
                    <div className='form-group'>
                      <label htmlFor='name'>Name:</label>
                      <input
                        id='name'
                        type='text'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className={errors.includes('Patient Name is required') ? 'input-error' : ''}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='phoneNo'>Phone No:</label>
                      <input
                        id='phoneNo'
                        type='text'
                        value={phoneNo}
                        onChange={(e) => setPhoneNo(e.target.value)}
                        className={errors.includes('Phone No is required') || errors.includes('Phone No must be a valid 10-digit number') ? 'input-error' : ''}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='age'>Age:</label>
                      <input
                        id='age'
                        type='number'
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        className={errors.includes('Age is required') || errors.includes('Please enter a valid age between 1 and 120') ? 'input-error' : ''}
                      />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='comment'>Comment:</label>
                      <textarea
                        id='comment'
                        rows={4}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </div>
                    {errors && <p className='error'>{errors}</p>}
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', width:'300px'}}>

                    <button type='submit' className='submit-button'>Save and Next</button>
                    <button className='back-button' onClick={() => window.location.reload()}>Back</button>
                    </div>
                  </form>
                </div>
              </animated.div>
            </div>
          )}

          {showPaymentOptions && (
            <animated.div className='payment-options-container' style={slideUp}>
              <>
              <div className='card'>
              <label style={{fontSize:'20px', fontWeight:'700',}}>
                  <input
                    type='radio'
                    name='paymentMethod'
                    value='Cash on Delivery'
                    checked={paymentMethod === 'Cash on Delivery'}
                    onChange={() => handlePaymentOptionSelect('Cash on Delivery')}
                  />
                  Cash 
                </label>
                <p>Pay via cash at OPD.</p>
              </div>
              
              <div className='card' >
              <label style={{fontSize:'20px', fontWeight:'700',}}>
                  <input
                    type='radio'
                    name='paymentMethod'
                    value='UPI'
                    checked={paymentMethod === 'UPI'}
                    onChange={() => handlePaymentOptionSelect('UPI')}
                  />
                  UPI
                </label>
                <p>Pay via UPI by scanning the QR code.</p>
              </div>
                {paymentMethod !== 'UPI' ? (
                   <div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', width:'300px'}}>
                  <button className='button' onClick={handleConfirmPayment}>
                   {paymentMethod === 'UPI' ? 'Confirm Payment' : 'Save'}
                 </button>
                 
                 <button className='back-button' onClick={() => setShowPaymentOptions(false)}>Back</button>
                 </div> ): null}
              </>
              {showQRCode && paymentMethod === 'UPI' ? (
                <div className='qr-container'>
                  <h1>Fees: ₹400</h1>
                  <p >Please provide a screenshot of the payment confirmation when you visit the doctor for the OPD consultation.</p>
                  <img src={payment_qr} alt='QR Code' className='qr-image' />
                  <p>Scan this QR code to pay via UPI.</p>
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', width:'300px'}}>
                  <button className='button' onClick={handleSaveDetails}>Confirm Payment</button>
                  <button className='back-button' onClick={() => setShowPaymentOptions(false)}>Back</button>
                  </div>
                </div>
                 ): ( null
                )}
                </animated.div>
          )}
        </>
      )}
      <div style={{display:'flex', flexDirection:'row', height:28}}><p style={{color:'red', fontSize:'1rem', paddingRight:'5px' }}>* </p><p style={{fontSize:'1rem', }}> Slot Booked can be changed as per emergency.</p></div>
      <p>Call at : 0562-4040405 / 7055467776</p>
    </div>
  );
};

export default App;

