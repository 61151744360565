import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { FaSmile } from 'react-icons/fa'; 
import logo from '../logo.jpg'
import './thankyou.css'

const ThankYouScreen = ({ onClose, selectedDate, selectedSlots }) => {
  const fadeIn = useSpring({ opacity: 1, from: { opacity: 0 }, config: { duration: 1000 } });
  const bounceIn = useSpring({ transform: 'scale(1)', from: { transform: 'scale(0)' }, config: { tension: 120, friction: 14 } });

  
  return (
    <animated.div className='thank-you-container' style={fadeIn}>
      <animated.img
        src={logo}
        alt="Thank You"
        className='thank-you-icon'
        style={bounceIn}
      />
      <h1 className='thank-you-message'>
        Thank You! Your appointment is successfully booked! 
      </h1>
      <h2 className='thank-you-message1'>
        Slot Booked can be changed as per emergency. You will be getting call for confirmation of appointment in 30 mins.
      </h2>
      <FaSmile size={80} color="#00796b" className='thank-you-icon' style={bounceIn} />
      <button className='thank-you-button' onClick={onClose}>
        Go Back to Home
      </button>
    </animated.div>
  );
};

export default ThankYouScreen;
