import React, { useState, useEffect } from 'react';
import db from '../components/firebase'
import {
  collection,
  getDocs,
} from "firebase/firestore";

const TimeSlot = ({ time, status, handleClick }) => {
  const slotStyle = {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '10px',
    margin: '5px',
    cursor: 'pointer',
    width: '200px',
    backgroundColor: status === 'available' ? '#b2f5ea' : (status === 'booked' ? '#f5b2b2' : '#e0e0e0'),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    
  
  };

  const buttonStyle = {
    padding: '5px 10px',
    borderRadius: '5px',
    backgroundColor: status === 'available' ? '#4CAF50' : '#F44336',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
  };

  return (
    <div style={slotStyle} >
      <span>{time}</span>
      {status === 'available' ? (
        <button style={buttonStyle} onClick={() => handleClick(time)}>Book Now</button>
      ) : (
        <span>Booked</span>
      )}
    </div>
  );
};

const TimeSlotList = ({ selectedDate, selectedSlots, handleSlotSelection }) => {
  const timeSlots = [];
  const startTime = new Date(selectedDate).setHours(10, 0, 0, 0); 
  const endTime = new Date(selectedDate).setHours(15, 45, 0, 0); 
  const [users, setUsers] = useState([]);

  const usersCollectionRef = collection(db, "Appointments");

  const getUsers = async () => {
    const data = await getDocs(usersCollectionRef);
    setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(() => {
    getUsers();
  }, []);

  
  let currentTime = startTime;
  while (currentTime <= endTime) {
    const time = new Date(currentTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }); 

    if (!isDuringBreakPeriod(currentTime)) {
      const formattedTime = new Date(currentTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
      const formattedDate = new Date(selectedDate).toLocaleDateString();

      const isBooked = users.some(user => {
        const userDate = new Date(user.App_time.seconds * 1000).toLocaleDateString();
        const userTime = new Date(user.App_time.seconds * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
        return userDate === formattedDate && userTime === time && user.Status === true;
      });

      const status = isBooked ? 'booked' : (selectedSlots.includes(time) ? 'booked' : 'available');

      timeSlots.push(
        <TimeSlot key={time} time={time} status={status} handleClick={handleSlotSelection} />
      );
    }

    currentTime += 15 * 60 * 1000; 
  }

  return (
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
    {/* <div style={}>  */}
      <h3>Available Time Slots for {selectedDate.toLocaleDateString()}</h3>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
        {timeSlots}
      </div>
    </div>
  );
};


const isDuringBreakPeriod = (time) => {
  const breakStart = new Date(time).setHours(12, 30, 0, 0);
  const breakEnd = new Date(time).setHours(13, 0, 0, 0);
  return time >= breakStart && time < breakEnd;
};



export default TimeSlotList;
