// import firebase from "firebase/compat/app";
// import { getFirestore } from "firebase/firestore";



// // if (!firebase.apps.length) {
// //   firebase.initializeApp(firebaseConfig);
// // }


// const firebaseApp = firebase.initializeApp(firebaseConfig);
// // const db = firebase.firestore();
// export const db = getFirestore(firebaseApp)


// export default db

import {initializeApp} from "firebase/app";
import {getFirestore} from "@firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyAx2OKs4aRQ_Z488kqzwObjqHWUQnPV7KY",
  authDomain: "docpr-ec97a.firebaseapp.com",
  projectId: "docpr-ec97a",
  storageBucket: "docpr-ec97a.appspot.com",
  messagingSenderId: "24504972524",
  appId: "1:24504972524:web:2cfb56cf2cf00f32f2e1c9",
  measurementId: "G-G72NL9PW14"
};
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export default db;