import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from "react-icons/fa";
import { useSpring, animated } from '@react-spring/web';

const DateSelector = ({ selectedDate, handleDateChange }) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
    const fadeIn = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 200 });
    // const pulse = useSpring({
    //   transform: 'scale(1)',
    //   from: { transform: 'scale(0.95)' },
    //   config: { tension: 200, friction: 10 },
    //   loop: { reverse: true },
    // });

    const glowEffect = {
      boxShadow: '0 4px 15px rgba(30, 64, 175, 0.5)',
    };

    return (
      <animated.div
        style={{
          ...fadeIn,
          // ...pulse,
          ...glowEffect,
          background: 'linear-gradient(135deg, #e0f7fa, #b3e5fc)',
          borderRadius: '12px',
          padding: '12px 20px',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          transition: 'box-shadow 0.3s ease',
        }}
        onClick={onClick}
        ref={ref}
        onMouseEnter={(e) => (e.currentTarget.style.boxShadow = '0 6px 20px rgba(30, 64, 175, 0.7)')}
        onMouseLeave={(e) => (e.currentTarget.style.boxShadow = '0 4px 15px rgba(30, 64, 175, 0.5)')}
      >
        <span style={{ marginRight: '12px', fontSize: '18px', fontWeight: 'bold', color: '#1e3a8a' }}>{value}</span>
        <FaCalendarAlt style={{ fontSize: '28px', color: '#0288d1' }} />
      </animated.div>
    );
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',  }}>
      <animated.h2 style={{
          fontSize: '24px',
          // color: '#1e3a8a',
          color: '#252735',
          fontWeight: '700',
          textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)',
          marginRight: '20px',
          opacity: 1,
          transform: 'translateY(0)',
          from: { opacity: 0, transform: 'translateY(-20px)' },
          delay: 100
        }}>
        Select Date:
      </animated.h2>
      <DatePicker
        customInput={<ExampleCustomInput />}
        selected={selectedDate}
        onChange={date => handleDateChange(date)}
        dateFormat="dd/MM/yyyy"
        minDate={new Date()}
        popperClassName="animated-popper"
      />
    </div>
  );
};



export default DateSelector;
